import React from "react";
import styled from "styled-components";

const End = styled.div`
  .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  h1 {
    font-size: 4em;
  }
  p {
    margin-top: 30px;
  }
`;

export default function end() {
  return (
    <End>
      <div className="center">
        <h1 className="mark">समाप्त</h1>
        <p>
          यह पुस्तक यहाँ खतम होता है। सद्गुरु यीशु के विषय में अधिक जानकारी के
          लिए
        </p>
        <a href="https://yeshusatsang.org" className="nav-button">
          यीशु सत्संग वेबसाईट
        </a>
        <p>को देखें</p>
      </div>
    </End>
  );
}
